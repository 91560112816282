import Vue from 'vue'
import axios from 'axios'

const url = 'https://api.volleyball.ch/indoor/ranking/'

const elements = document.getElementsByClassName('rangliste-wrapper')
for (const el of elements) {
  initRangliste(el)
}

function initRangliste(el) {
  const team = el.getAttribute('data-team') || null

  new Vue({
    el,
    data: {
      items: [],
    },
    mounted() {
      if (team) {
        this.fetch()
      }
    },
    methods: {
      async fetch() {
        try {
          const {data} = await axios.get(url + team, {headers: {Authorization: 'clicsoftGmbhMasterApiKey'}})
          this.items = data
        } catch (e) {}
      },
    }
  })
}
