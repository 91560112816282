import $ from 'jquery';

$(".next-slide").on("click", function(e) {
    $('html, body').animate({
        scrollTop: $(this).parent().nextAll().eq(1).offset().top  - 0
    }, 800);
});

$(".top-slide").on("click", function () {
    $("html, body").animate({ scrollTop: "0" }, 600);
});