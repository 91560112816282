// custom dependencies

//z.B.:
import slick from 'slick-carousel';

const deps = {
  slick : slick
};


export default deps
