import $ from "jquery";
import "what-input";
import libs from "./lib/dependencies.js";

window.$ = $;
window.jQuery = $;
window.libs = libs;

//import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import 'foundation-sites'
import "./lib/foundation-explicit-pieces.js";

//Pixmill Scripts
import { ready } from "./px/utils.js";

import "./px/hamburger.js";
import "./px/slidedown.js";
import "./px/opaque.js";
import "./px/form.js";
import "./px/lazyload.js";
import { addFunctionalityToProgressPath } from "./px/scrollProgress.js";

ready(function () {
  addFunctionalityToProgressPath("progress-wrap");
});

// * * * * * * * * * * * * * * * * * * * * * * * * *
// * popUp
// *
// *

ready(function (){
$("[data-open]").click(function(event){
  event.preventDefault();
  var id = $(this).data("open");
  window.location.hash = id;
});

// open popup if exists
if(window.location.hash) {
  var hash = window.location.hash;
  if (hash && hash != 'a' && $(hash).length) {
    openPopUp($(hash));
  }
}

// open popUp
function openPopUp($object) {
  $($object).foundation("open")
  //$("html, body").addClass("freeze");
}

$('[data-reveal]').on('closed.zf.reveal', function () {
  var modal = $(this);
  window.location.hash = "";
});


});

$(document).ready(function(){


  $('.resultate').slick({
    autoplay: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    prevArrow: '<span class="svg-icon slick-arrow slick-prev"><svg class="icon"><use xlink:href="#icon-arrow-open"></use></svg></span>',
    nextArrow: '<span class="svg-icon slick-arrow slick-next"><svg class="icon"><use xlink:href="#icon-arrow-open"></use></svg></span>',
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  $('.logo-slider').slick({
    autoplay: true,
    infinite: true,
    slidesToShow: 10,
    slidesToScroll: 1,
    autoplaySpeed: 0,
    speed: 2400,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 7
        }
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 4
        }
      }
    ]
  });

});

$(document).ready(function(){

  $('.map-box .info').click(function(){
    $(this).parent().find('.overlay').addClass('show');
  });
  $('.map-box .close').click(function(){
    $(this).parent().removeClass('show');
  });

});

$(document).foundation();
