import Vue from 'vue'
import {BCalendar} from 'bootstrap-vue'
import axios from 'axios'
import {format, formatISO, parseISO as dateParse, endOfMonth, startOfMonth} from 'date-fns'
import de from 'date-fns/locale/de/index.js'

const url = '/assets/app/connector.php?q=/web/competitions'

const dateFormat = (date, template = 'yyyy-MM-dd') => {
  if (typeof date === 'string') {
    date = dateParse(date)
  }
  return format(date, template, {locale: de})
}

const elements = document.getElementsByClassName('competitions-wrapper')
for (const el of elements) {
  initCalendar(el)
}

function initCalendar(el) {
  const team = el.getAttribute('data-team') || null
  const limit = el.getAttribute('data-limit') || 8

  new Vue({
    el,
    components: { BCalendar },
    data: {
      date: '',
      calTitle: dateFormat(new Date(), 'MMMM'),
      dates: [],
      dateFormat,
      dateParse,
    },
    computed: {
      boxTitle() {
        return this.date !== '' ? dateFormat(dateParse(this.date), 'EEEE, dd.MM.yyyy'): 'NÄCHSTE SPIELE'
      },
      canClose() {
        return this.date !== ''
      },
      competitions() {
        const competitions = this.date !== ''
          ? this.dates.filter((i) => i.date.split(' ')[0] === this.date)
          : [...this.dates]
        return competitions.slice(0, limit)
      },
      calProps() {
        const dates = [...this.dates]
        return {
          labelHelp: '',
          hideHeader: true,
          showDecadeNav: false,
          block: true,
          locale: 'de',
          startWeekday: 1,
          dateDisabledFn: (val) => {
            return !this.dates.filter((i) => {
              return i.date.split(' ')[0] === val
            }).length
          },
          min: dates.length ? dates.shift().date.split(' ')[0] : dateFormat(new Date(), 'yyyy-MM-01'),
          max: dates.length ? dates.pop().date.split(' ')[0] : dateFormat(endOfMonth(new Date()), 'yyyy-MM-dd')
        }
      },
      prevMonthDisabled() {
        const minDate = startOfMonth(dateParse(this.calProps.min))
        if (this.$refs.cal) {
          const prevDate = startOfMonth(dateParse(this.$refs.cal.activeYMD))
          return formatISO(minDate) === formatISO(prevDate)
        }
        return true
      },
      nextMonthDisabled() {
        const maxDate = endOfMonth(dateParse(this.calProps.max))
        if (this.$refs.cal) {
          const nextDate = endOfMonth(dateParse(this.$refs.cal.activeYMD))
          return formatISO(maxDate) === formatISO(nextDate)
        }
        return true
      }
    },
    mounted() {
      this.fetch()
    },
    methods: {
      async fetch() {
        try {
          const {data} = await axios.get(url, {params: {team}})
          this.dates = data.rows
        } catch (e) {}
      },
      onClose() {
        this.date = ''
      },
      nextMonth() {
        this.$refs.cal.gotoNextMonth()
        this.calTitle = dateFormat(this.$refs.cal.activeYMD, 'MMMM')
      },
      prevMonth() {
        this.$refs.cal.gotoPrevMonth()
        this.calTitle = dateFormat(this.$refs.cal.activeYMD, 'MMMM')
      },
    }
  })
}
